import React from "react";
import { css } from "@emotion/react";
import tw from "twin.macro";
import linkResolver from "../utils/linkResolver";
import { Link, graphql } from "gatsby";
import GatsbyImage from "gatsby-image";
export default function CTA({ data }) {
  return (
    <div className="text-black bg-white">
      <div className="container max-w-5xl py-12 lg:py-20">
        <div className="grid grid-cols-2 gap-12 md:grid-cols-4">
          {data?.items?.map((item, index) => {
            return (
              <div key={index}>
                {" "}
                <a href={item?.link?.url}>
                  <GatsbyImage fluid={item?.logo?.fluid} />
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export const query = graphql`
  fragment Logos on PrismicHomepageBodyCustomerLogos {
    slice_type
    id
    items {
      logo {
        fluid(maxWidth: 200) {
          ...GatsbyPrismicImageFluid
        }
        alt
      }
      link {
        link_type
        uid
        url
        type
        target
      }
    }
  }
`;

import React, { useRef, useState } from "react";
import { graphql, Link } from "gatsby";
import GatsbyImage from "gatsby-image";
import formatDate from "date-fns/format";
import useEvents from "../_api/useEvents";

import { useQueryClient } from "react-query";
import { css } from "@emotion/react";
import tw from "twin.macro";
import { MdKeyboardArrowRight } from "react-icons/md";
import { StaticImage } from "gatsby-plugin-image";
import { FaSpinner } from "react-icons/fa";
import Scrollbar from "./Scrollbar";
export default function EventsV3() {
  const {
    events,

    ...query
  } = useEvents();

  const queryClient = useQueryClient();
  function prefetch(id) {
    queryClient.prefetchQuery(["event", id], () => {
      return fetch(`/api/event?event_id=${id}`).then((res) => res.json());
    });
  }
  return (
    <div className="relative py-10 overflow-x-hidden lg:py-20 max-w-screen">
      <div className="container relative z-10">
        <div>
          <h1 className="mb-8 text-3xl font-bold tracking-wide text-center uppercase lg:mb-12 lg:text-4xl">
            Events
          </h1>
        </div>

        <Scrollbar>
          <div className="flex items-center pb-6 space-x-6 ">
            {query.isLoading &&
              Array.from({ length: 4 }).map((item, index) => {
                return (
                  <div
                    key={index}
                    className="h-64 bg-blue-light/50 animate-pulse w-72 md:w-[300px] lg:w-[400px]"
                  ></div>
                );
              })}
            {events?.map((event) => {
              return (
                <div className="shadow flex-shrink-0 font-bold dark:text-white dark:bg-blue-dark w-72 md:w-[300px] lg:w-[400px] bg-white  text-black">
                  {" "}
                  <Link
                    onMouseOver={() => {
                      prefetch(event.id);
                    }}
                    to={`/event/${event.id}`}
                  >
                    <div class="pb-[calc(9/16*100%)] relative w-full">
                      <img
                        src={event?.cover?.source}
                        className="absolute inset-0 object-cover"
                      />
                    </div>
                  </Link>{" "}
                  <div className="flex items-center justify-between p-3">
                    <div>
                      <small>
                        {formatDate(
                          new Date(event.start_time),
                          "MMM dd , hh:mm aa"
                        )}
                      </small>
                      <h4 className="font-bold "> {event.name}</h4>
                      <Link
                        className="inline-block px-3 py-1 mt-2 ml-auto text-xs uppercase bg-blue-light text-blue-dark"
                        onMouseOver={() => {
                          prefetch(event.id);
                        }}
                        to={`/event/${event.id}`}
                      >
                        MEHR
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
            {query.hasNextPage && (
              <div
                onClick={() => {
                  query.fetchNextPage();
                }}
                className="cursor-pointer self-stretch flex-shrink-0 font-bold dark:text-white/80 dark:bg-blue-dark/80 w-72 md:w-[300px] lg:w-[400px] bg-white  text-black flex items-center justify-center  "
              >
                Load more{" "}
                {query.isFetching && (
                  <FaSpinner className="ml-3 animate-spin" />
                )}
              </div>
            )}
          </div>
        </Scrollbar>
      </div>
    </div>
  );
}

import React, { useRef, useState } from "react";
import { graphql, Link } from "gatsby";
import GatsbyImage from "gatsby-image";
import { css } from "@emotion/react";
import { MdKeyboardArrowRight } from "react-icons/md";
import { StaticImage } from "gatsby-plugin-image";
import Scrollbar from "./Scrollbar";
import tw from "twin.macro";
import cn from "classnames";
export default function Locations({ data }) {
  return (
    <div className="relative py-10 overflow-x-hidden lg:py-20 max-w-screen">
      <div className="container relative z-10">
        <div>
          <h1 className="mb-8 text-3xl font-bold tracking-wide text-center uppercase lg:mb-12 lg:text-4xl">
            Locations
          </h1>
        </div>
        <Scrollbar>
          <div className="flex items-center pb-6 ">
            {data?.items?.map(({ location: item }, index, items) => {
              const data = item?.document?.data;
              return (
                <div
                  className={cn(
                    "flex-shrink-0 font-bold dark:text-white   w-72 md:w-[300px]   pr-6 last:pr-0",
                    {
                      "lg:w-[400px]": items?.length > 3,
                      "lg:w-1/3": items?.length == 3,
                      "lg:w-1/2": items?.length == 2,
                    }
                  )}
                >
                  <div className="bg-white shadow">
                    <Link to={"/location/" + item.slug}>
                      <GatsbyImage
                        className="h-40 lg:h-56"
                        fluid={data?.image?.fluid}
                      />
                    </Link>{" "}
                    <div className="flex items-center justify-between p-3">
                      <span className="text-black ">{data.title.text}</span>{" "}
                      <Link
                        to={"/location/" + item.slug}
                        className="inline-block px-3 py-1 ml-auto text-xs uppercase bg-blue-light text-blue-dark"
                      >
                        Mehr{" "}
                        <MdKeyboardArrowRight className="inline-block -mt-0.5" />
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </Scrollbar>
      </div>
    </div>
  );
}

export const query = graphql`
  fragment BodyLocations on PrismicHomepageBodyLocations {
    items {
      location {
        slug
        document {
          ... on PrismicLocation {
            ...Location
          }
        }
      }
    }
  }
  fragment Location on PrismicLocation {
    uid
    data {
      title {
        text
      }
      reservations_modal
      text {
        html
      }
      image {
        alt
        fluid {
          ...GatsbyPrismicImageFluid
        }
      }
    }
  }
`;
